import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import getImage from 'components/getImage';
import { styles, InfosContainer, CardContainer } from './styles';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
// import formatAmount from 'helpers/formatAmount';
import Wrapper from 'components/Wrapper';
import { getDiffDays } from 'modules/mobiOne/helpers/getDiffDays';

function DealListCard(props) {
  const { classes, deal, canceled = null } = props;

  let linkImage = null;
  if (deal?.imageId || deal?.images?.length) {
    linkImage = getImage(
      {
        imageId: deal?.imageId || deal?.images?.[0].imageId,
        imageVcode: deal?.images?.[0].imageVcode,
      },
      80,
      290,
      0
    );
  }

  return (
    <CardContainer canceled={canceled}>
      <CardMedia
        className={classes.cover}
        image={linkImage || require('assets/default-image.svg')}
        title="Carro"
      />
      <InfosContainer>
        <Wrapper flex justify="evenly" direction="column" alignSelf="initial">
          {canceled === true && (
            <CardActions style={{ padding: '0 8px' }}>
              <Typography component="p" className={classes.typeError}>
                Cancelada
              </Typography>
            </CardActions>
          )}
          {canceled === false && (
            <CardActions style={{ padding: '0 8px' }}>
              <Typography component="p" className={classes.typeSuccess}>
                Ativa
              </Typography>
            </CardActions>
          )}
          <CardActions
            classes={{ root: classes.title }}
            style={{ padding: '0 8px', margin: 0 }}
          >
            {deal?.fullName ? (
              <Typography component="p" className={classes.modelName}>
                {deal?.fullName}
              </Typography>
            ) : (
              <Typography component="p" className={classes.modelName}>
                {deal?.title ||
                  `${deal?.makeName || ''} ${deal?.modelName ||
                    ''} ${deal?.externalVehicleName || deal?.trimName || ''}`}
              </Typography>
            )}
          </CardActions>
          <CardActions
            classes={{ root: classes.spec }}
            style={{ padding: '0 8px', margin: 0 }}
          >
            {deal?.dealerName && (
              <Tooltip title={deal?.dealerName}>
                <Typography component="p" className={classes.types}>
                  {deal?.dealerName}
                </Typography>
              </Tooltip>
            )}
            {(deal?.exteriorColor || deal?.colorName) && (
              <Tooltip title="Cor">
                <Typography component="p" className={classes.types}>
                  {deal?.exteriorColor || deal?.colorName}
                </Typography>
              </Tooltip>
            )}
            {deal?.vin && (
              <Tooltip title="Chassi">
                <Typography component="p" className={classes.types}>
                  {deal?.vin}
                </Typography>
              </Tooltip>
            )}
            {deal?.plate && (
              <Tooltip title="Placa">
                <Typography component="p" className={classes.types}>
                  {deal?.plate}
                </Typography>
              </Tooltip>
            )}
            {(deal?.productionYear || deal?.initialYear) &&
              (deal?.modelYear || deal?.finalYear) && (
                <Tooltip title="Ano Modelo/Fabricação">
                  <Typography component="p" className={classes.types}>
                    {deal?.productionYear || deal?.initialYear}/
                    {deal?.modelYear || deal?.finalYear}
                  </Typography>
                </Tooltip>
              )}
            {!!deal?.km && (
              <Tooltip title="Kilometragem">
                <Typography component="p" className={classes.types}>
                  {`${deal?.km?.toLocaleString('pt-BR')}Km`}
                </Typography>
              </Tooltip>
            )}
            {/* {(deal?.vehiclePrice || deal?.price) && (
              <Tooltip title="Preço">
                <Typography component="p" className={classes.types}>
                  {formatAmount(
                    deal?.vehiclePrice || deal?.price,
                    'PT-BR',
                    'R$'
                  )}
                </Typography>
              </Tooltip>
            )} */}
            {deal?.inStockDate && (
              <Tooltip title="Dias em estoque">
                <Typography component="p" className={classes.types}>
                  {getDiffDays(deal?.inStockDate)}
                </Typography>
              </Tooltip>
            )}
            {deal?.booked && (
              <Typography component="p" className={classes.bookedAt}>
                {`Reservado até ${moment(deal?.bookedAt).format('DD/MM')}`}
              </Typography>
            )}
          </CardActions>
        </Wrapper>
      </InfosContainer>
      {props.selectComponent && (
        <Wrapper flex justify="evenly" direction="column" p={8} height="100%">
          {props.selectComponent}
        </Wrapper>
      )}
    </CardContainer>
  );
}

export default withStyles(styles)(DealListCard);
